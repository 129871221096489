<template>
  <div class="blocks-item-black col-span-12 block lg:flex black-1">
    <div class="blocks-black-title">
      <h2 v-html="block.title"></h2>
    </div>
    <div v-html="block.content" class="flex-[1] pt-[64px] pb-[40px] px-4 lg:px-[44px] block-content"></div>
  </div>
</template>

<script>
export default {
  name: "BlockBlack",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.blocks-item-black {
  position: relative;
  background: #202020 !important;
  color: #fff;

  .blocks-black-title {
    position: relative;
    z-index: 1;
    padding: 124px 147px 196px 0;

    h2 {
      @apply m-0 text-left max-w-full text-white text-center font-black text-[40px] leading-[50px] lg:max-w-[306px] lg:text-left;
    }
  }

  &::v-deep {
    padding: 0;

    p {
      color: #fff;
    }

    ol, ul {
      margin-bottom: 20px;
    }

    ol {
      max-width: 100%;

      li {
        &::before {
          background-color: #fff;
          color: #202020;
        }
      }
    }

    ul {
      max-width: 100%;

      li {
        @apply mb-[14px] last:mb-0;
      }
    }
  }
}

.black-1 {
  .blocks-black-title {
    position: relative;

    &:after {
      content: '';
      background: #202020 url("/images/blocks-black-bg.png") right center;
      position: absolute;
      height: 100%;
      width: 100vw;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  .block-content {
    position: relative;

    &:after {
      content: '';
      background-color: #202020;
      position: absolute;
      height: 100%;
      width: 100vw;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}

@media all and (max-width: 1024px) {
  .blocks-item-black {
    position: relative;
    left: -16px;
    margin-left: -16px;
    margin-right: 16px;
    width: calc(100% + 32px);
  }

  .blocks-black-title::after,
  .block-content::after {
    display: none;
  }

  .blocks-item-black {
    margin: 0;

    &:before {
      display: none;
    }
  }

  .blocks-item-black .blocks-black-title {
    background: #202020 url("/images/blocks-black-bg.png") no-repeat center / cover;
    padding: 52px 0 60px;
    text-align: center;
  }
}
</style>
