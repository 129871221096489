<template>
  <div class="blocks-item col-span-12">
    <h2> <span v-html="block.title"></span></h2>
    <div class="content-text" v-html="block.content"></div>
  </div>
</template>

<script>
export default {
  name: "BlockNav",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
  h2 {
    text-align: center;
    position: relative;
    overflow: hidden;

    span {
      position: relative;
      &:after, &:before {
        content: '';
        position: absolute;
        height: 2px;
        width: 100vw;
        background-color: #ff0000;
        top: 50%;
      }
      &:after {
        left: calc(100% + 30px);
      }
      &:before {
        right: calc(100% + 30px);
      }
    }
  }
  ::v-deep {
    .content-text {
      ul, ol {
        margin: auto;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        min-width: 50%;
        li {
          margin-left: 60px;
        }
      }
    }
    a {
      text-decoration: underline;
    }
  }
</style>
