<template>
  <div v-if="blocks?.length" class="blocks">
    <div class="website-blocks-gap grid grid-cols-12 max-w-1432 px-4 mx-auto">
      <component
        v-for="block in blocks"
        :key="block.id"
        :is="getBlockByType(block.type)"
        :block="block"
      />
    </div>
  </div>
</template>

<script>
import BlockBlack from "./BlockBlack";
import BlockOthers from "./BlockOthers";
import BlockRoom from "./BlockRoom";
import BlockNav from "./BlockNav";

export default {
  name: "WebsiteBlocks",
  components: {BlockRoom, BlockOthers, BlockBlack, BlockNav},
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getBlockByType(type) {
      let componentName = 'BlockOthers';

      switch (type) {
        case 'black':
          componentName = 'BlockBlack';
          break;
        case 'room':
          componentName = 'BlockRoom';
          break;
        case 'nav':
          componentName = 'BlockNav';
          break;
      }

      return componentName;
    },
  }
}
</script>

<style lang="scss" scoped>
.website-blocks-gap {
  grid-column-gap: 38px;
}

@media all and (max-width: 1024px) {
  .website-blocks-gap {
      grid-column-gap: 24px;
  }
}
</style>
