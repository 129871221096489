<template>
  <div>
    <div class="tabs px-4 grid grid-cols-12 max-w-1192">
      <a
        v-for="tab in tabs"
        :key="`tab-${tab.name}`"
        href="#"
        :class="{active: activeTab(tab.name)}"
        class="tab c-btn col-span-4 block bg-transparent text-darker-200 font-semibold text-[20px] leading-[27px] border border-darker-200 border-l-0 rounded-[0px] first:border-l"
        @click.prevent="handleSelectTab(tab)"
      >
        {{ tab.title }}
      </a>
    </div>

    <div class="tabs-multi max-w-1432 px-4">
      <Multiselect
        name="type"
        track-by="title"
        valueProp="name"
        label="title"
        class='custom-multiselect'
        :searchable="false"
        :options="tabs"
        :can-clear="false"
        placeholder="Не выбрано"
        noResultsText="Не найдено"
        noOptionsText="Не найдено"
        @select="handleSelectTab"
        v-model="multiTab"
      />
    </div>

    <div v-if="content[multiTab] && content[multiTab].content" class="max-w-1432 page-content-wrap px-4 mx-auto">
      <div class="content-text" v-html="content[multiTab].content"></div>
    </div>

    <WebsiteBlocks v-if="content[multiTab] && content[multiTab].blocks" :blocks="content[multiTab].blocks" />
  </div>
</template>

<script>
import WebsiteBlocks from "../../../components/blocks/WebsiteBlocks";
import Multiselect from '@vueform/multiselect';

export default {
  components: {WebsiteBlocks, Multiselect},
  data() {
    return {
      tabs: [
        {
          title: 'Классические румы',
            name: 'classic',
        },
        {
          title: 'Экзотика',
            name: 'ekzotika',
        },
        {
          title: 'Приложения',
          name: 'apps',
        },
      ],
      multiTab: null,
        content: {
            classic: null,
            ekzotika: null,
            apps: null,
        },
    }
  },
  methods: {
    async fetchContent(name) {
      try {
        const { data } = await this.axios.get(`/content/${name}`);

        if (data) {
            this.content[name] = data.data;
        }
      } catch (ex) {
        console.log('cant fetch content: ', ex);
      }
    },
    handleSelectTab(tab) {
      const name = typeof tab === 'object' ? tab.name : tab;
      this.multiTab = name;
      this.$router.push(`/#${name}`);
    },
      activeTab(tab) {
          return tab === this.multiTab;
      },
  },
    async created() {
        await this.fetchContent('classic');
        await this.fetchContent('ekzotika');
        await this.fetchContent('apps');
    },
    mounted() {
        this.multiTab = this.$route.hash ? this.$route.hash.split('#')[1] : 'classic';
    },
}
</script>
<style scoped lang='scss'>
.tabs {
  margin: 60px auto 40px;
}

.tabs-multi {
  display: none;
}

.tab {
  &:hover,
  &:focus {
    @apply bg-darker-200 text-white text-opacity-75;
  }

  &:hover,
  &:focus,
  &.active {
    @apply bg-darker-200 text-white;
  }

  &.active {
    pointer-events: none;
  }
}

@media all and (max-width: 860px) {
  .tabs {
    display: none;
  }

  .tabs-multi {
    display: block;
    margin: 44px auto 24px;
  }

  .custom-multiselect {
    max-width: 360px;
    color: #fff;
    border-color: #202020;
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
    line-height: 22px;

    &::v-deep {
      .multiselect-wrapper {
        @apply bg-darker-200;
      }

      .multiselect-search::placeholder,
      .multiselect-search {
        @apply bg-darker-200 text-white;
      }

      .multiselect-caret,
      .multiselect-clear-icon {
        background-color: #fff;
      }

      .multiselect-option.is-selected {
        background-color: #E70020;
      }

      .multiselect-dropdown {
        background-color: #202020;
        color: #fff;
      }

      .multiselect-option {
        border-bottom: 1px solid #fff;

        &:hover,
        &:focus {
          cursor: pointer;
          transition: all .3s ease;
          background-color: rgba(#E70020, 0.9);
          color: #fff;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
