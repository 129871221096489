<template>
  <div class="blocks-item col-span-12">
    <h2 v-html="block.title"></h2>
    <div v-html="block.content"></div>
  </div>
</template>

<script>
export default {
  name: "BlockOthers",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
